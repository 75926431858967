// extracted by mini-css-extract-plugin
export var logo = "staff-module--logo--Yy92U";
export var spacer = "staff-module--spacer--qTE2d";
export var container = "staff-module--container--CZiHd";
export var staffWrap = "staff-module--staffWrap--joRts";
export var textCol = "staff-module--textCol--WT29n";
export var imageCol = "staff-module--imageCol--VlZQ1";
export var toOurTeamLink = "staff-module--toOurTeamLink--DBNvV";
export var toOurTeamIcon = "staff-module--toOurTeamIcon--wGJsr";
export var toOurTeamText = "staff-module--toOurTeamText--o4+h0";
export var staffName = "staff-module--staffName--GOePj";
export var staffPosition = "staff-module--staffPosition--LhDMy";
export var staffBio = "staff-module--staffBio--mg4D8";
export var socialWrap = "staff-module--socialWrap--0kcx+";
export var socialItem = "staff-module--socialItem--TkWXD";
export var bioImage = "staff-module--bioImage--IRllU";
export var bioImageCredit = "staff-module--bioImageCredit--4SoYT";
export var projectsCallout = "staff-module--projectsCallout---VSDw";
export var projectsCalloutImage = "staff-module--projectsCalloutImage--H+4Eu";
export var projectsCalloutHeading = "staff-module--projectsCalloutHeading--N+KSD";
export var projectsButtonWrap = "staff-module--projectsButtonWrap--dKhtf";
export var textLine = "staff-module--textLine--6rXXE";
export var socialShareMobile = "staff-module--socialShareMobile--88vSq";
export var slideUp = "staff-module--slideUp--PU2q5";
export var slideUpLarge = "staff-module--slideUpLarge--yQ4PU";